import { TrackingEvent } from '../types';

export type EventData = Record<string, unknown>;

const ignoredEvents: TrackingEvent[] = ['Impression', 'EnquirySubmit'];

export const AD_EVENTS_TO_MERGE = ['Ad_Impression', 'Ad_Serve'];

const legacyEventMapping = new Map<TrackingEvent, string>([
    ['PageView', 'pageview'],
    ['Website', 'website'],
    ['Share', 'share'],
    ['EmailReveal', 'Email_reveal'],
    ['PhoneReveal', 'Phone_reveal'],
    ['LocationReveal', 'Location_reveal'],
    ['MobileReveal', 'Mobile_reveal'],
    ['SaveSubmit', 'save'],
]);

export function isIgnoredEvent(event: TrackingEvent): boolean {
    return ignoredEvents.includes(event);
}

export function convertLegacyEventName(event: TrackingEvent): TrackingEvent {
    const legacyEventName = legacyEventMapping.get(event);
    if (legacyEventName) {
        return legacyEventName as TrackingEvent;
    }
    return event;
}

export const isLibraryPage = (url: string): boolean => {
    const libraryDetailPage = [
        '/product/',
        '/professional/',
        '/project/',
        '/article/',
        '/digital-magazine/',
    ];
    return libraryDetailPage.some(path => url.startsWith(path));
};

interface AdData {
    professionalId: number;
    adIds: number[];
}

export const mergeAdEvents = (events: EventData[]): EventData[] => {
    const result: EventData[] = [];
    /**
     * format:
     * { <event_name>: <first_event_in_list> }
     */
    const adEvents: Record<string, EventData> = {};
    /**
     * format:
     * { <event_name>: { <professional_id>: <ad_ids> } }
     */
    const adDataMap: Record<string, Record<number, number[]>> = {};
    events.forEach(eventData => {
        if (
            typeof eventData['event'] !== 'string' ||
            !AD_EVENTS_TO_MERGE.includes(eventData['event'])
        ) {
            // handle normal events
            result.push(eventData);
            return;
        }

        // handle ad events
        try {
            const adData = extractAdData(eventData);
            if (!adData) {
                // invalid data/ should not happen. just keep the event in list
                result.push(eventData);
                return;
            }
            const adEventName = eventData['event'];
            if (!adEvents[adEventName]) {
                adEvents[adEventName] = eventData;
            }
            mergeAdEventData(adEventName, adData, adDataMap);
        } catch (_) {
            // avoid breaking the whole process
            // fallback to keep the event in list
            result.push(eventData);
            return;
        }
    });

    try {
        return result.concat(composeAdEvents(adEvents, adDataMap));
    } catch (_) {
        // avoid breaking the whole process
        // fallback to the original events
        return events;
    }
};

// extract adData from extraData
const extractAdData = (eventData: EventData): AdData | null => {
    let extraData = eventData['ExtraData'];
    if (!extraData) {
        // invalid data. should not happen.
        return null;
    }
    if (typeof extraData === 'string') {
        extraData = JSON.parse(extraData);
    }

    if (!extraData || typeof extraData !== 'object') {
        return null;
    }

    let professionalId =
        'professionalId' in extraData ? extraData['professionalId'] : null;
    if (typeof professionalId === 'string') {
        professionalId = parseInt(professionalId);
    }
    if (!professionalId || typeof professionalId !== 'number') {
        return null;
    }

    const adIds = 'adIds' in extraData ? extraData['adIds'] : null;
    if (!Array.isArray(adIds)) {
        return null;
    }

    return {
        professionalId,
        adIds: adIds as number[],
    };
};

// group adIds by event name and professionalId
const mergeAdEventData = (
    adEventName: string,
    adData: AdData,
    adDataMap: Record<string, Record<number, number[]>>
) => {
    if (!adDataMap[adEventName]) {
        adDataMap[adEventName] = {};
    }
    const prevAdData = adDataMap[adEventName];
    if (!prevAdData[adData.professionalId]) {
        prevAdData[adData.professionalId] = [];
    }
    prevAdData[adData.professionalId] = (
        prevAdData[adData.professionalId] ?? []
    ).concat(adData.adIds);
};

const composeAdEvents = (
    adEvents: Record<string, EventData>,
    adDataMap: Record<string, Record<number, number[]>>
): EventData[] => {
    const result: Record<string, unknown>[] = [];
    for (const adEventName in adEvents) {
        const eventData = adEvents[adEventName];
        const adData = adDataMap[adEventName];
        if (!adData) {
            continue;
        }
        for (const professionalId in adData) {
            const adIds = adData[professionalId];
            const uniqueAdIds = [...new Set(adIds)];
            result.push({
                ...eventData,
                ExtraData: {
                    // key will be converted to string. Now convert it back to number
                    professionalId: parseInt(professionalId),
                    adIds: uniqueAdIds,
                },
            });
        }
    }

    return result;
};
